/* src/components/Hero.css */

/* Section Styling */
.hero-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: black;
}

.hero-container {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align items to left and right */
}

/* Left Side: Title and Description */
.hero-content {
  max-width: 50%; /* Take up half the width */
  margin-left: 8rem; /* Padding from the left */
}

.hero-title {
  font-size: 4rem; /* Increased title size */
  font-weight: 700;
  color: #f3f4f6;
  margin-bottom: 1.5rem;
}

/* Typewriter Text */
.typewriter-text {
  color: #8b5cf6; /* Purple color for typed text */
}

.hero-subtitle {
  font-size: 1.5rem;
  color: #d1d5db;
  margin-bottom: 2rem;
}

/* Button */
.hero-button {
  background: #8b5cf6; /* Purple background */
  color: #f3f4f6; /* White text */
  padding: 1rem 2.5rem; /* Larger padding */
  border: none;
  width: 300px;
  border-radius: 0.5rem;
  font-size: 1.25rem; /* Larger font size */
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .hero-button a{
    color: inherit;
    text-decoration: none;
  }

.hero-button:hover {
  background: #7c3aed; /* Darker purple on hover */
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.hero-button:active {
  transform: translateY(0); /* Reset on click */
}

/* Right Side: Image */
.hero-image-container {
  max-width: 50%; /* Take up half the width */
  flex-shrink: 0;
  margin-right: 8rem; /* Padding from the right */
}

.hero-image {
  width: 350px; /* Smaller size */
  height: 350px; /* Make it a square */
  border-radius: 50%; /* Round shape */
  object-fit: cover; /* Ensure the image fits well */
  border: 4px solid #8b5cf6; /* Optional: Add a border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content,
  .hero-image-container {
    max-width: 100%; /* Full width on mobile */
    margin-left: 2rem; /* Reduced padding for mobile */
    margin-right: 2rem; /* Reduced padding for mobile */
  }

  .hero-title {
    font-size: 2.5rem; /* Adjusted for mobile */
  }

  .hero-subtitle {
    font-size: 1.25rem;
  }

  .hero-image {
    width: 200px; /* Smaller size for mobile */
    height: 200px;
  }
}