/* src/components/AboutMe.css */

/* Section Styling */
.about-section {
    background: #111827; /* bg-gray-900 */
    padding: 5rem 2rem; /* py-20 px-8 */
  }
  
  .about-container {
    max-width: 72rem; /* max-w-6xl */
    margin: 0 auto;
  }
  
  /* Heading */
  .about-heading {
    font-size: 2.5rem; /* text-4xl */
    font-weight: 700; /* font-bold */
    color: #f3f4f6; /* text-gray-100 */
    text-align: center;
    margin-bottom: 1rem; /* mb-4 */
  }
  
  /* Subheading */
  .about-subheading {
    font-size: 1.875rem; /* text-3xl */
    font-weight: 600; /* font-semibold */
    color: #8b5cf6; /* text-purple-500 */
    text-align: center;
    margin-bottom: 2rem; /* mb-8 */
  }
  
  /* Description */
  .about-description {
    font-size: 1.125rem; /* text-lg */
    color: #d1d5db; /* text-gray-300 */
    text-align: center;
    max-width: 800px;
    margin: 0 auto 3rem; /* mb-12 */
    line-height: 1.75;
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-heading {
      font-size: 2rem; /* text-3xl */
    }
  
    .about-subheading {
      font-size: 1.5rem; /* text-2xl */
    }
  
    .about-description {
      font-size: 1rem; /* text-base */
    }
  
   
  }