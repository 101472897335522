/* src/components/Footer.css */

/* Section Styling */
.footer-section {
  background: #1f2937; /* bg-gray-800 */
  padding: 5rem 2rem 2rem; /* py-20 px-8 pt-20 pb-8 */
}

.footer-container {
  max-width: 72rem; /* max-w-6xl */
  margin: 0 auto;
}

/* Footer Content */
.footer-content {
  display: flex;
  justify-content: space-between;
  gap: 4rem; /* gap-16 */
  margin-bottom: 3rem; /* mb-12 */
}

/* Left Side: Contact Info */
.contact-info {
  max-width: 400px;
}

.footer-heading {
  font-size: 2rem; /* text-3xl */
  font-weight: 700; /* font-bold */
  color: #f3f4f6; /* text-gray-100 */
  margin-bottom: 1rem; /* mb-4 */
}

.footer-description {
  font-size: 1rem; /* text-base */
  color: #d1d5db; /* text-gray-300 */
  margin-bottom: 2rem; /* mb-8 */
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* gap-4 */
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 0.75rem; /* gap-3 */
  font-size: 1rem; /* text-base */
  color: #f3f4f6; /* text-gray-100 */
}

.contact-icon {
  color: #8b5cf6; /* text-purple-500 */
}

/* Right Side: Social Links */
.social-links {
  text-align: right;
}

.social-heading {
  font-size: 1.5rem; /* text-2xl */
  font-weight: 600; /* font-semibold */
  color: #f3f4f6; /* text-gray-100 */
  margin-bottom: 1rem; /* mb-4 */
}

.social-icons {
  display: flex;
  gap: 1.5rem; /* gap-6 */
}

.social-icon {
  color: #f3f4f6; /* text-gray-100 */
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #8b5cf6; /* text-purple-500 */
}

/* Footer Bottom */
.footer-bottom {
  border-top: 1px solid #374151; /* border-gray-700 */
  padding-top: 2rem; /* pt-8 */
  text-align: center;
}

.copyright {
  font-size: 0.875rem; /* text-sm */
  color: #9ca3af; /* text-gray-400 */
}
span a {
  color: inherit; /* Inherits the color from the parent element */
  text-decoration: none; /* Removes the underline */
}

span a:hover {
  color: inherit; /* Keeps the color consistent on hover */
  text-decoration: none; /* Ensures no underline appears on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 2rem; /* gap-8 */
  }

  .social-links {
    text-align: left;
  }

  .footer-heading {
    font-size: 1.875rem; /* text-2xl */
  }

  .social-heading {
    font-size: 1.25rem; /* text-xl */
  }
}