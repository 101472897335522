body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  background: black;
  color: white;
  padding: 15px 40px; /* Increased padding */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto; /* Center the navbar */
}

.navbar-logo {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: white;
}

.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-menu li {
  margin-left: 30px; /* Increased margin */
}

.navbar-menu a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s ease;
}

.navbar-menu a:hover {
  color: #4CAF50; /* Hover effect */
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.navbar-toggle .bar {
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 4px 0;
  transition: transform 0.3s ease;
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #1a1a1a;
    position: absolute;
    top: 70px; /* Adjusted top position */
    left: 0;
    padding: 20px 0;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-menu li {
    margin: 10px 0;
    text-align: center;
  }

  .navbar-toggle {
    display: flex;
  }

  .navbar-toggle.active .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .navbar-toggle.active .bar:nth-child(2) {
    opacity: 0;
  }

  .navbar-toggle.active .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
}