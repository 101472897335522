/* src/components/SkillStack.css */

/* Section Styling */
.skill-stack-section {
    background: #1f2937; /* bg-gray-800 */
    padding: 5rem 2rem; /* py-20 px-8 */
  }
  
  .skill-stack-container {
    max-width: 72rem; /* max-w-6xl */
    margin: 0 auto;
  }
  
  /* Heading */
  .skill-stack-heading {
    font-size: 2.5rem; /* text-4xl */
    font-weight: 700; /* font-bold */
    color: #f3f4f6; /* text-gray-100 */
    text-align: center;
    margin-bottom: 3rem; /* mb-12 */
  }
  
  /* Skills Grid */
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem; /* gap-8 */
  }
  
  .skill-category {
    background: #111827; /* bg-gray-900 */
    padding: 1.5rem; /* p-6 */
    border-radius: 0.75rem; /* rounded-xl */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .skill-category:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .category-title {
    font-size: 1.5rem; /* text-2xl */
    font-weight: 600; /* font-semibold */
    color: #f3f4f6; /* text-gray-100 */
    margin-bottom: 1.5rem; /* mb-6 */
  }
  
  .skill-items {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* gap-4 */
  }
  
  .skill-item {
    margin-bottom: 1rem; /* mb-4 */
  }
  
  .skill-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem; /* mb-2 */
  }
  
  .skill-name {
    font-size: 1rem; /* text-base */
    color: #f3f4f6; /* text-gray-100 */
  }
  
  .skill-percentage {
    font-size: 1rem; /* text-base */
    color: #8b5cf6; /* text-purple-500 */
  }
  
  .progress-bar {
    background: #374151; /* bg-gray-700 */
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .progress-fill {
    background: #8b5cf6; /* bg-purple-500 */
    height: 100%;
    border-radius: 4px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .skill-stack-heading {
      font-size: 2rem; /* text-3xl */
    }
  
    .skills-grid {
      grid-template-columns: 1fr;
    }
  }